body {
  background-color: rgb(1, 7, 34);
  margin: 0;
  overflow-y: hidden;
}
h2 {
  font-size: 24px;
  font-weight: normal;
}
.login-container {
  background-color: rgb(3, 13, 53);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid white;
  padding: 25px;
  box-sizing: border-box;
  width: 80%;
  height:max-content;
  max-width: 300px; /* Adjusted max-width for smaller screens */
  /* margin: 0 auto; */
  margin-left: 60%;
  margin-right: 10%;
  margin-bottom: 20%;
  margin-top: 5%;
}
.login-container h2 {
  color: #fff;
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group input {
  width: 90%;
  height: 45px; /* Adjusted height for better mobile experience */
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
}
.form-group button {
  margin-top: 20px;
  background-color: rgb(1, 7, 34);
  color: #fff;
  /* border: none; */
  border-color: #fff;
  padding: 10px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: normal;
  width: 40%;
  height: 40px; /* Adjusted height for better mobile experience */
  font-size: 14px; /* Adjusted font size for better mobile experience */
}
.form-group button:hover {
  background-color: #e74c3c;
}
.fas {
  color: white;
}
form {
  margin-top: 40px; /* Adjusted margin for better mobile spacing */
}
@media (max-width: 480px) {
  .login-container {
    width: 90%; /* Adjusted width for better mobile experience */
  }
  .form-group button {
    font-size: 16px; /* Adjusted font size for better mobile experience */
  }
  .client-content {
    display: none;
  }
  .display-flex {
    flex-direction: column; /* Adjusted flex direction for better mobile layout */
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
.error-message{
  color: #fff;
}

.login-container i{
  color: #0F1035;
}



/* @media (max-width: 480px) {
  .login-container {
    width: 90%;
  }

  .form-group button {
    font-size: 16px;
  }

  .client-content {
    display: none;
  }

  .display-flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  } 
} */


@media (max-width: 1024px) {
  .login-container {
    width: 90%;
    margin: 0 auto; /* Center the container horizontally on smaller screens */
  }
}

@media (max-width: 480px) {
  .login-container {
    width: 100%;
    margin: 0 auto; /* Center the container horizontally on smaller screens */
  }
}

/* Updated Registration.css */

/* ... (unchanged styles) */

.row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-1 img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1025px) {
  .login-container {
    width: 90%;
    margin: 0 auto;
  }

  .center-of-page{
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .row-1 {
    flex-direction: column;
    align-items: center;
    justify-content: center; 
  }

  .img {
    width: 100%;
    max-width: 150px;
    /* margin-top: -30px; */
  }

  .admin{
    margin-top: -30px;
  }

  .bolt{
    margin-left: -90px;
  }
  .form-group button {
    font-size: 16px;
  }

  .client-content {
    display: none;
  }

  .display-flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}

/* ... (unchanged styles) */