/* Modal overlay styles */
.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }
  
  /* Modal container styles */
  .modal {
    background: #0F1035;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    position: relative;
    z-index: 3;
  }

  .list-table {
    overflow-y: auto; /* Enable vertical scrollbar */
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #92a6e2d0 #1E1E1E; /* Scrollbar color */
  }
  /* Define scrollbar styles */
  .list-table::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  .list-table::-webkit-scrollbar-track {
    background: #92A6E2; /* Track color */
  }
  .list-table::-webkit-scrollbar-thumb {
    background-color: #92A6E2; /* Thumb color */
    border-radius: 10px; /* Border radius of the thumb */
  }
  .list-table::-webkit-scrollbar-thumb:hover {
    background-color: #888888; /* Thumb color on hover */
  }

  .modal label{
    color: #ffffff;
  }
  
  /* Close button styles */
  .close-button {
    background: none;
    display: flex;
    justify-content: end;
    border: none;
    color: #ff0000;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  /* Input and label styles */
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  input {
    flex: 1;
    padding: 8px;
    box-sizing: border-box;
  }
  
  /* Button styles */
  .submit {
    background-color: #4caf50;
    color: #fff;
    margin-top: 10px;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;
  }
  
  .submit:hover {
    background-color: #45a049;
  }
  