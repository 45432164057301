/* Form styling */
.pop-up-form {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px; /* Adjust the gap as needed */
  }
  
  /* .form-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  
  label {
    grid-column: 1 / 2;
    grid-row: span 1;
    align-self: center;
    font-weight: bold;
  }
  
  input {
    grid-column: 2 / 3;
    grid-row: span 1;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Customize additional form elements as needed */
  
  /* Example styles for submit and cancel buttons */
  .submit-button,
  .cancel-button {
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
  
  .submit-button {
    background-color: #27ae60;
  }
  
  .cancel-button {
    background-color: #e74c3c;
  }
  
h2{
    color: #ddd;
}

.form-submit-btn {
    padding: 10px 20px;
    background-color: #27ae60;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: -50px;
  }
  
  .form-submit-btn:hover {
    background-color: #4360b5;
  }

  .error-message{
    color: #ff0000;
    font-size: 12px;
  }
  
  .submit-btn{
    background: #27ae60;
    border: none;
    /* box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1); */
    color: #fff;
    padding: 10px;
    height: 40px;
    font-size: 14px;
    width: max-content;
    border-radius: 10px;
    display: flex;
    align-items: end;
    cursor: pointer;
    transition: all 0.3s ease;
  }



  .submit-btn:hover{
  color: #0F1035;
  background: #fff ;
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1);
  /* font-size: 14.1px; */
}

.delete-emp{
  background: #ff0000;
}
.success-message {
  color: green; /* or any other color you prefer */
  font-weight: bold;
  margin-top: 10px;
}
.btn-align{

  display: flex;
  align-items: flex-start;
}
