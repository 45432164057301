@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap');

*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Nunito Sans', sans-serif;
}

body{
  background: #03001C;
}
.wrapper{
  margin: 10px;
}
.wrapper .top_navbar{
  width: calc(100% - 20px);
  height: 60px;
  display: flex;
  position: fixed;
  top: 10px;
}

.wrapper .top_navbar .hamburger{
  width: 70px;
  height: 100%;
  background: #0F1035;
  padding: 15px 17px;
  border-top-left-radius: 20px;
  cursor: pointer;
}

.wrapper .top_navbar .hamburger div{
  width: 35px;
  height: 4px;
  background: #92a6e2;
  margin: 5px 0;
  border-radius: 5px;
}

.wrapper .top_navbar .top_menu{
  width: calc(100% - 70px);
  height: 100%;
  background: #0F1035;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 0px; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.wrapper .top_navbar .top_menu .logo{
  color: #E55604;
  font-size: 24px;
  font-weight: 700;
  /* letter-spacing: 3px; */
}

.wrapper .top_navbar .top_menu ul{
  display: flex;
}

.wrapper .top_navbar .top_menu ul li a{
  display: block;
  margin: 0 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #2e4ead;
  border-radius: 50%;
  color: #2e4ead;
}

.wrapper .top_navbar .top_menu ul li a:hover{
  background: #4360b5;
  color: #fff;
}

.wrapper .top_navbar .top_menu ul li a:hover i{
  color: #fff;
}

.wrapper .sidebar{
  position: fixed;
  top: 70px;
  left: 10px;
  background: #0F1035;
  width: 205px;
  height: calc(100% - 80px);
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease;
}

.wrapper .sidebar ul li a{
    display: block;
    padding: 20px;
    color: #fff;
    position: relative;
    margin-bottom: 1px;
    color: #fafbff;
    white-space: nowrap;
    cursor: pointer;
}

.wrapper .sidebar ul li a:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #92a6e2;
  display: none;
}

.wrapper .sidebar ul li a span.icon{
  margin-right: 10px;
  margin-top: -10px;
  display: inline-block;
  font-size: small;
}

.wrapper .sidebar ul li a {
  display: flex;
  align-items: center;
  padding: 20px;
  color: #fff;
  position: relative;
  margin-bottom: 1px;
  color: #fafbff;
  white-space: nowrap;
  cursor: pointer;
}

.wrapper .sidebar ul li a span.icon {
  margin-right: 10px; /* Adjust the margin as needed */
}

.wrapper .sidebar ul li a span.icon svg {
  height: 20px; 
  margin-top: 12px;
}

.wrapper .sidebar ul li a span.icon.sign-out svg {
  /* height: 20px;  */
  /* margin-top: 24px; */
  rotate: 180deg;

}


.top{
  margin-top: 10px;
}

/* .wrapper .sidebar ul li a span.icon img{
  width: 24px;
  height: 24px;
} */

.wrapper .sidebar ul li a span.title{
  display: inline-block;
}


.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active{
  background: #4360b5;
  color: #fff;
}

.wrapper .sidebar ul li a:hover{
  background: #4360b5d0;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before{
  display: block;
}

.wrapper .main_container{
  width: (100% - 200px);
  margin-top: 70px;
  margin-left: 200px;
  padding: 15px;
  transition: all 0.3s ease;
}

.wrapper.collapse .sidebar{
  width: 70px;
}

.wrapper.collapse .sidebar ul li a{
  text-align: center; 
}

.wrapper.collapse .sidebar ul li a span.icon{
  margin: 0;
}

.wrapper.collapse .sidebar ul li a span.title{
  display: none;
}

.wrapper.collapse .main_container{
  width: (100% - 70px);
  margin-left: 70px;
}



.nav-menu-icon{
    width: 32px;
    height: 32px;
}

.dashboard-mainpage-h1{
  color: #E55604;
  margin-bottom: 20px;
}

.wrapper .main_container .list-table{
  background: #0F1035;
  margin-bottom: 10px;
  padding: 0px;
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 10px;
  margin-top: -15px;  
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.table-container-1 {
  overflow-x: auto;
}

.list-table table {
  border-collapse: collapse;
  width: 100%; /* Ensure table takes up full width */
}


.list-table th,
.list-table td {
  padding: -8px;
  text-align: left;
}

.list-table th,
.list-table .action-column {
  position: sticky;
  top: 0;
  background-color: #0F1035; /* Optional: Add background color to differentiate header */
  z-index: 2; /* Ensure header stays above tbody content */
}

.list-table th {
  z-index: 3; /* Ensure header stays above action-column */
}

.list-table .action-column {
  z-index: 3; /* Ensure action column stays above tbody content */
}


table {
  width: 100%;
  background: none;
  /* margin-top: 20px; */
}

th, td {
  border: none;
  padding: 10px;
  text-align: left;
}

th, td {
  /* background-color: #f2f2f2; */
  color: #fff;
}

.action-column {
  width: 100px;
  text-align: center;
  display: flex;
}

.action-column .add-employee-btn {
  padding: 5px 10px;
  cursor: pointer;
  /* margin-left: 10px; */
  border-radius: 10px;
  color: #fff;
  background: #03001C;
  width: max-content;
  border: none;
}

.delete{
  color: red
}

.main-page-header{
  display: flex;
  justify-content: space-between;
}

.add-employee-btn{
  background: #0F1035;
  border: none;
  /* box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1); */
  color: #fff;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  width: max-content;
  border-radius: 10px;
  display: flex;
  align-items: end;
  cursor: pointer;
  transition: all 0.3s ease;
}

.manage-employee-btn{
  background: #0F1035;
  border: none;
  /* box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1); */
  color: #fff;
  padding: 10px;
  height: 35px;
  font-size: 14px;
  width: max-content;
  border-radius: 10px;
  display: flex;
  align-items: end;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-employee-btn:hover,
.manage-employee-btn:hover{
  color: #0F1035;
  background: #fff ;
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1);
  /* font-size: 14.1px; */
}

#pagination-container {
  /* margin-top: 10px; */
  text-align: center;
  background-color: #0F1035;
  /* margin-bottom: -15px; */
  padding: 10px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  /* width: 100%; */
  /* margin-left: -15px; */
  /* margin-right: -15px; */
  position: sticky;
}


.pagination-page-number{
  color: #fff;
  /* background-color: #0F1035; */
  padding: 10px;
  border-radius: 50px;
  margin-bottom: auto;
  
}

.pagination-btn {
  padding: 8px 12px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;
  background-color: #0F1035;
  padding: 10px;
  border-radius: 50px;
}

.pagination-btn:disabled:hover {
  color: slategray;
  cursor: not-allowed;
}


.upload-csv{
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
}

.center-container {
  text-align: center;
}

.upload-form {
  background-color: #0F1035;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  color: #fff;
  align-items: center;
}

h1 {
  margin-bottom: 10px;
  color: #E55604;
  font-size: large;
}

.file-label {
  display: inline-block;
  padding: 8px 16px;
  background-color: #3498db;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.file-input {
  display: none;
}

#fileName {
  display: inline-block;
  margin-left: 10px;
  font-style: italic;
}

.upload-csv-btn {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-csv-btn:hover {
  background-color: #27ae60;
}

.upload-form p{
  margin-top: 20px;
}
.file-label {
  margin-top: -20px;
}

.div-center{
  display: flex;
  justify-content: center;
  align-items: center;
}


.main_container {
  flex-grow: 1;
  padding: 20px;
  transition: margin 0.5s;
}

.padding{
  padding: 40px;
}

.main-page-header {
  text-align: center;
  margin-bottom: 20px;
}

.center-text{
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.template-section p {
  margin: 5px 0;
}

.download-btn {
  padding: 10px 20px;
  background-color: #27ae60;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-btn:hover {
  background-color: #4360b5;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
  margin: 20px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-container-1 {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  margin: 20px auto;
  padding: 20px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: calc(75vh - 130px);
  overscroll-behavior: contain;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.table-container th, td {
  /* border: 1px solid #ddd; */
  padding: 6px;
  text-align: left;
}


.table-container .pagination-container {
  text-align: center;
  margin-top: 15px;
}

.table-container .pagination-btn {
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #0F1035;
  color: #fff;
  border: none;
  border-radius: 10px;
}

.table-container .pagination-page-number {
  display: inline-block;
  align-items: flex-start;
  margin: 0 5px;
  font-size: 16px;
}

/* Add these styles to your CSS file */

.pagination-page-number.current-page {
  color: #E55604;
}

/* Add these styles to your CSS file */

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-start {
  order: 1;
}

.pagination-middle {
  order: 2;
}

.pagination-end{
  order: 3;
}

/* Responsive styles */
@media (max-width: 600px) {
  .table-container .table-container {
    padding: 10px;
  }
  .table-container th, td {
    font-size: 12px;
  }
  .add-employee-btn,
  .manage-employee-btn{
    font-size: 10px;
  }
  .display-none{
    display: none;
  }

}

/* Your existing styles */

.client-content {
  color: white;
  text-align: left;
  padding: 20px;
}

.client-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.client-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff;
}

/* Add the following responsive styles */
@media (max-width: 768px) {
  .client-content {
    display: none;
  }
  
}

.action-btn{
  background: none;
  border: none;
}

.edit{
  color: #fff;
}

.edit:hover,
.delete:hover{
  background: #0F1035;
  border-radius: 10px;
  scale: 1.2;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-btn:hover{
  color: #E55604;
}


/* Form css */

/* Add these styles to your existing CSS file */

/* Form container */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.popup-close {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}
.logout-button,
.cancel-button {
  background: #27ae60;
  border: none;
  /* box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1); */
  color: #fff;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  width: max-content;
  border-radius: 10px;
  display: flex;
  align-items: end;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-button {
  background-color: #27ae60;
  margin-top: 10px;

}

.cancel-button {
  background-color: #e74c3c;
}

.logout-button:hover{
  background-color: #ffffffd0;
  color: #0F1035;
}

.cancel-button:hover{
  background-color: #ffffffd0;
  color: #0F1035;
}
.form-label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.form-label p{
  color: #fff;
}

.form-row{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 20px;
}


.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  /* Add any other styling for disabled state */
}


/* CSS for pagination */
#pagination-container {
  /* margin-top: 10px; */
  text-align: center;
  background-color: #0F1035;
  /* margin-bottom: -15px; */
  padding: 10px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  /* width: 100%; */
  /* margin-left: -15px; */
  /* margin-right: -15px; */
  position: sticky;
}


.pagination-page-number{
  color: #fff;
  /* background-color: #0F1035; */
  padding: 10px;
  border-radius: 50px;
  margin-bottom: auto;
  
}

.pagination-btn {
  padding: 8px 12px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;
  background-color: #0F1035;
  padding: 10px;
  border-radius: 50px;
}

.pagination-btn:disabled:hover {
  color: slategray;
  cursor: not-allowed;
}

.display-gone{
  color: #0F1035;
  cursor: auto;
  user-select: none;
}

.display-gone:hover{
  color: #0F1035;
}

.current-page{
  color: #E55604;
  font-weight: bolder;
  cursor: text;
}

.total-pages{
  cursor: text;
}

.total-pages:hover{
  color: white;
}


/* Regular pagination styles */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-start,
.pagination-end {
  display: flex;
  align-items: center;
}

.pagination-btn {
  /* Your button styles */
}

.pagination-page-number {
  /* Your page number button styles */
}

.pagination-current-page {
  color: white;
  font-size: smaller;
}

/* Media query for mobile devices */
@media screen and (max-width: 767px) {
  .pagination-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  .pagination-end {
    margin-top: 10px; /* Adjust as needed */
  }
}

.orange{
  color: #E55604;
}

.flex1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

a, a:hover, a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

/* ------------ */

.card {
     box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    z-index: 3;

}
.dropdown-item-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 3;
}

.dropdown-container {
  position: relative; /* Ensure the dropdown container is positioned relative to its parent */
}

.dropdown-link {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 18px;
    color: #fff;
    background-color:#4360b5;
    z-index: 3;
    font-weight: 500;
}

.dropdown-link span {
    display: inline-block;
    z-index: 3;

}

.dropdown-link i {
    margin-left: 10px;
    z-index: 3;

}

.dropdown-box {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: max-content; /* Set the width to the content width */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}


.dropdown-box ul {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
    z-index: 3;

}

.dropdown-box ul li {
    list-style: none;
    z-index: 3;

}

.dropdown-box ul li .data-link {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    line-height: initial;
    color: #475F7B;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    z-index: 3;

}

.dropdown-box ul li a i {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
}

.dropdown-box ul li .data-link:hover {
    color: #475F7B;
    background-color: #e8ebef;
    z-index: 3;

}

.dropdown-animation-enter {
    opacity: 0;
    transform: translateY(10px);
}

.dropdown-animation-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms, transform 300ms;
}

.dropdown-animation-exit {
    opacity: 1;
    transform: translateY(0px);
}

.dropdown-animation-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
}

.modal {
  background: #0F1035;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.close-button {
  background: none;
  display: flex;
  justify-content: end;
  border: none;
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.search-div {
  position: relative;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.custom-search-bar {
  padding-right: 40px; /* Adjust as needed */
  width: 200px; /* Adjust as needed */
}

.search-button {
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  background-color: #4360b5;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.search-button:hover {
  background-color: #2e4a9e; /* Darken the color slightly on hover */
}

.no-data-message .centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.no-data-message h1,
.error-msg {
  color: red;
  font-size: medium;
}